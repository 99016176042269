/* ImageSelector.css */

.image-selector {
    display: flex;
    justify-content: start;
    align-items: start;
    margin-top: 20px;
    flex-direction: column;
}

.overlay {
    background-color: aqua;
}

.image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-item {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    color: #7B73F4;
    cursor: pointer;
    font-size: 40px;
    border: 1px dashed #7B73F4;
    border-radius: 5px;
    border-width: 1px;
    border-style: dashed;
    border-color: #7B73F4;
}

.remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
}